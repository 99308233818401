/* components/BelvoLinks.svelte generated by Svelte v3.28.0 */
import {
	SvelteComponent,
	append,
	attr,
	destroy_block,
	destroy_each,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	set_data,
	space,
	text,
	update_keyed_each
} from "svelte/internal";

import { onMount } from "svelte";
import Axios from "axios";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[7] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[10] = list[i];
	return child_ctx;
}

// (53:8) {#if loading || fetching}
function create_if_block(ctx) {
	let p;

	return {
		c() {
			p = element("p");
			p.textContent = "Cargando...";
			attr(p, "class", "fl ml2 mt4");
		},
		m(target, anchor) {
			insert(target, p, anchor);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

// (62:8) {#each columns as column}
function create_each_block_1(ctx) {
	let th;
	let t_value = /*column*/ ctx[10] + "";
	let t;

	return {
		c() {
			th = element("th");
			t = text(t_value);
		},
		m(target, anchor) {
			insert(target, th, anchor);
			append(th, t);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(th);
		}
	};
}

// (66:4) {#each links as link (link.id)}
function create_each_block(key_1, ctx) {
	let tr;
	let td0;
	let t0_value = /*link*/ ctx[7].institution + "";
	let t0;
	let t1;
	let td1;
	let t2_value = /*link*/ ctx[7].id + "";
	let t2;
	let t3;
	let td2;
	let t4_value = /*link*/ ctx[7].status + "";
	let t4;
	let t5;
	let button;
	let t6;
	let t7;
	let mounted;
	let dispose;

	function click_handler(...args) {
		return /*click_handler*/ ctx[6](/*link*/ ctx[7], ...args);
	}

	return {
		key: key_1,
		first: null,
		c() {
			tr = element("tr");
			td0 = element("td");
			t0 = text(t0_value);
			t1 = space();
			td1 = element("td");
			t2 = text(t2_value);
			t3 = space();
			td2 = element("td");
			t4 = text(t4_value);
			t5 = space();
			button = element("button");
			t6 = text("X");
			t7 = space();
			button.disabled = /*loading*/ ctx[1];
			this.first = tr;
		},
		m(target, anchor) {
			insert(target, tr, anchor);
			append(tr, td0);
			append(td0, t0);
			append(tr, t1);
			append(tr, td1);
			append(td1, t2);
			append(tr, t3);
			append(tr, td2);
			append(td2, t4);
			append(tr, t5);
			append(tr, button);
			append(button, t6);
			append(tr, t7);

			if (!mounted) {
				dispose = listen(button, "click", click_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*links*/ 1 && t0_value !== (t0_value = /*link*/ ctx[7].institution + "")) set_data(t0, t0_value);
			if (dirty & /*links*/ 1 && t2_value !== (t2_value = /*link*/ ctx[7].id + "")) set_data(t2, t2_value);
			if (dirty & /*links*/ 1 && t4_value !== (t4_value = /*link*/ ctx[7].status + "")) set_data(t4, t4_value);

			if (dirty & /*loading*/ 2) {
				button.disabled = /*loading*/ ctx[1];
			}
		},
		d(detaching) {
			if (detaching) detach(tr);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let div1;
	let h1;
	let t1;
	let t2;
	let div0;
	let t3;
	let table;
	let tr;
	let t4;
	let each_blocks = [];
	let each1_lookup = new Map();
	let if_block = (/*loading*/ ctx[1] || /*fetching*/ ctx[2]) && create_if_block(ctx);
	let each_value_1 = /*columns*/ ctx[3];
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = /*links*/ ctx[0];
	const get_key = ctx => /*link*/ ctx[7].id;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	return {
		c() {
			div2 = element("div");
			div1 = element("div");
			h1 = element("h1");
			h1.textContent = "Belvo Links Panel";
			t1 = space();
			if (if_block) if_block.c();
			t2 = space();
			div0 = element("div");
			t3 = space();
			table = element("table");
			tr = element("tr");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			t4 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(h1, "class", "fl");
			attr(div0, "class", "cf");
			attr(div1, "class", "w-80");
			attr(div2, "class", "pa4");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div1);
			append(div1, h1);
			append(div1, t1);
			if (if_block) if_block.m(div1, null);
			append(div1, t2);
			append(div1, div0);
			append(div2, t3);
			append(div2, table);
			append(table, tr);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(tr, null);
			}

			append(table, t4);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(table, null);
			}
		},
		p(ctx, [dirty]) {
			if (/*loading*/ ctx[1] || /*fetching*/ ctx[2]) {
				if (if_block) {
					
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, t2);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty & /*columns*/ 8) {
				each_value_1 = /*columns*/ ctx[3];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(tr, null);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (dirty & /*loading, deleteRow, links*/ 19) {
				const each_value = /*links*/ ctx[0];
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each1_lookup, table, destroy_block, create_each_block, null, get_each_context);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div2);
			if (if_block) if_block.d();
			destroy_each(each_blocks_1, detaching);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}
		}
	};
}

let API_URL = "https://mugello.staging.digitt.com/api";

function instance($$self, $$props, $$invalidate) {
	let { token } = $$props;
	let links = [];
	let columns = ["Institution", "Uuid", "status"];
	let loading = false;
	let fetching = false;

	onMount(async () => {
		try {
			const linksResponse = await Axios.get(`${API_URL}/v2/belvo/links`, {
				headers: { Authorization: `Bearer ${token}` }
			});

			$$invalidate(0, links = linksResponse.data.results);
		} catch(err) {
			
		}

		setInterval(
			async () => {
				if (loading === false) {
					$$invalidate(2, fetching = true);

					const linksResponse = await Axios.get(`${API_URL}/v2/belvo/links`, {
						headers: { Authorization: `Bearer ${token}` }
					});

					$$invalidate(0, links = linksResponse.data.results);
					$$invalidate(2, fetching = false);
				}
			},
			10000
		);
	});

	async function deleteRow(linkUuid) {
		console.log("Delete", linkUuid);
		$$invalidate(1, loading = true);

		try {
			await Axios.delete(`${API_URL}/v2/belvo/links/${linkUuid}`, {
				headers: { Authorization: `Bearer ${token}` }
			});
		} catch(error) {
			
		}

		$$invalidate(0, links = links.filter(link => link.id !== linkUuid));
		$$invalidate(1, loading = false);
	}

	const click_handler = link => deleteRow(link.id);

	$$self.$$set = $$props => {
		if ("token" in $$props) $$invalidate(5, token = $$props.token);
	};

	return [links, loading, fetching, columns, deleteRow, token, click_handler];
}

class BelvoLinks extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { token: 5 });
	}
}

export default BelvoLinks;