/* components/Login.svelte generated by Svelte v3.28.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	listen,
	noop,
	prevent_default,
	run_all,
	safe_not_equal,
	set_input_value,
	space
} from "svelte/internal";

import { createEventDispatcher, onMount } from "svelte";
import Axios from "axios";

function create_fragment(ctx) {
	let div4;
	let form;
	let fieldset;
	let legend;
	let t1;
	let div0;
	let label0;
	let t3;
	let input0;
	let t4;
	let div1;
	let label1;
	let t6;
	let input1;
	let t7;
	let div2;
	let t8;
	let div3;
	let mounted;
	let dispose;

	return {
		c() {
			div4 = element("div");
			form = element("form");
			fieldset = element("fieldset");
			legend = element("legend");
			legend.textContent = "Sign In";
			t1 = space();
			div0 = element("div");
			label0 = element("label");
			label0.textContent = "User";
			t3 = space();
			input0 = element("input");
			t4 = space();
			div1 = element("div");
			label1 = element("label");
			label1.textContent = "Password";
			t6 = space();
			input1 = element("input");
			t7 = space();
			div2 = element("div");
			div2.innerHTML = `<input classname="b ph3 pv2 input-reset ba b--black bg-transparent grow pointer f6 dib" type="submit" value="Sign in"/>`;
			t8 = space();
			div3 = element("div");
			attr(legend, "classname", "f4 fw6 ph0 mh0");
			attr(label0, "classname", "db fw6 lh-copy f6");
			attr(label0, "for", "email-address");
			attr(input0, "classname", "pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100");
			attr(input0, "type", "text");
			attr(input0, "name", "user");
			attr(input0, "id", "username");
			attr(div0, "classname", "mt3");
			attr(label1, "classname", "db fw6 lh-copy f6");
			attr(label1, "for", "password");
			attr(input1, "classname", "b pa2 input-reset ba bg-transparent hover-bg-black hover-white w-100");
			attr(input1, "type", "password");
			attr(input1, "name", "password");
			attr(input1, "id", "password");
			attr(div1, "classname", "mv3");
			attr(fieldset, "id", "sign_up");
			attr(fieldset, "classname", "ba b--transparent ph0 mh0");
			attr(div2, "classname", "");
			attr(div3, "classname", "lh-copy mt3");
			attr(form, "classname", "measure center");
		},
		m(target, anchor) {
			insert(target, div4, anchor);
			append(div4, form);
			append(form, fieldset);
			append(fieldset, legend);
			append(fieldset, t1);
			append(fieldset, div0);
			append(div0, label0);
			append(div0, t3);
			append(div0, input0);
			set_input_value(input0, /*user*/ ctx[0]);
			append(fieldset, t4);
			append(fieldset, div1);
			append(div1, label1);
			append(div1, t6);
			append(div1, input1);
			set_input_value(input1, /*password*/ ctx[1]);
			append(form, t7);
			append(form, div2);
			append(form, t8);
			append(form, div3);

			if (!mounted) {
				dispose = [
					listen(input0, "input", /*input0_input_handler*/ ctx[3]),
					listen(input1, "input", /*input1_input_handler*/ ctx[4]),
					listen(form, "submit", prevent_default(/*onSubmit*/ ctx[2]))
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*user*/ 1 && input0.value !== /*user*/ ctx[0]) {
				set_input_value(input0, /*user*/ ctx[0]);
			}

			if (dirty & /*password*/ 2 && input1.value !== /*password*/ ctx[1]) {
				set_input_value(input1, /*password*/ ctx[1]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div4);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	const dispatch = createEventDispatcher();
	let user;
	let password;

	async function onSubmit(e) {
		const response = await Axios.post("https://medici.staging.digitt.com/api/v1/login", { user, password });
		dispatch("login", { token: response.data.token });
	}

	function input0_input_handler() {
		user = this.value;
		$$invalidate(0, user);
	}

	function input1_input_handler() {
		password = this.value;
		$$invalidate(1, password);
	}

	return [user, password, onSubmit, input0_input_handler, input1_input_handler];
}

class Login extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Login;